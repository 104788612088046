import React from 'react'
import { observer, inject } from 'mobx-react'
import ReactFullpage from '@fullpage/react-fullpage'
import { css } from '@emotion/core'

/* global MouseEvent */

import ProjectContainer from './container'
import ProjectCursor from './cursor'

const ProjectIndex = inject(`projectStore`)(
  observer(
    class ProjectIndex extends React.Component {
      constructor(props) {
        super(props)

        this.state = {
          activeSectionIndex: 0,
          projectInfoLinkHover: false,
          visible: false
        }

        this.fullpageApi = false
        this.handleSliderReady = this.handleSliderReady.bind(this)
        this.handleSlideLeave = this.handleSlideLeave.bind(this)
        this.handleLinkMouseEnter = this.handleLinkMouseEnter.bind(this)
        this.handleLinkMouseLeave = this.handleLinkMouseLeave.bind(this)
      }

      componentDidUpdate(prevProps) {
        const { projectStore, navOpen } = this.props
        const { project, projects } = projectStore
        const { activeSectionIndex } = this.state
        if (project.id !== projects[activeSectionIndex].id) {
          const newSection = projects.findIndex((p, i) => {
            return p.id === project.id
          })
          this.fullpageApi.moveTo(newSection + 1)
        }

        if (!navOpen && prevProps.navOpen) {
          const event = new MouseEvent('mouseleave', {
            view: window,
            bubbles: true,
            cancelable: true
          })
          document.querySelector('header .nav-closed').dispatchEvent(event)
        }
      }

      handleSliderReady() {
        this.setState({ visible: true })
      }

      handleSlideLeave(origin, destination, direction) {
        const { projectStore, splashOpen, navOpen } = this.props

        if (splashOpen || navOpen) return false

        const { index } = destination
        const { id } = projectStore.projects[index]
        projectStore.updateActiveProject(id)
        this.setState({ activeSectionIndex: index })
      }

      handleLinkMouseEnter() {
        this.setState({ projectInfoLinkHover: true })
      }

      handleLinkMouseLeave() {
        this.setState({ projectInfoLinkHover: false })
      }

      render() {
        const { projectInfoLinkHover, visible } = this.state

        const {
          splashOpen,
          navOpen,
          projectStore
        } = this.props

        const {
          projects,
          audible,
          videoMode,
          videoButtonHover,
          infoButtonHover,
          infoOpen,
          loading,
          isTouch
        } = projectStore

        return (
          <>
            <ReactFullpage
              licenseKey={'558CE3FE-BEC149CA-8863BA1E-2C015323'}
              onLeave={this.handleSlideLeave}
              afterRender={this.handleSliderReady}
              scrollingSpeed={800}
              verticalCentered={false}
              normalScrollElements={'header'}
              easingcss3={'cubic-bezier(.36,.01,.35,1)'}
              render={({ state, fullpageApi }) => {
                if (!this.fullpageApi) {
                  this.fullpageApi = fullpageApi
                }
                return (
                  <div css={css(`
                    opacity: ${visible ? 1 : 0};
                  `)}>
                    {projects.map((project, i) =>
                      <ProjectContainer
                        key={`project-${project.id}`}
                        project={project}
                        splashOpen={splashOpen}
                        navOpen={navOpen}
                        isTouch={isTouch}
                        onLinkMouseEnter={this.handleLinkMouseEnter}
                        onLinkMouseLeave={this.handleLinkMouseLeave}
                      />
                    )}
                  </div>
                )
              }}
            />
            {(!isTouch && !splashOpen) ?
              <ProjectCursor
                onTouchStart={this.handleTouchStart}
                audible={audible}
                videoMode={videoMode}
                videoButtonHover={videoButtonHover}
                infoButtonHover={infoButtonHover}
                infoOpen={infoOpen}
                loading={loading}
                projectInfoLinkHover={projectInfoLinkHover}
              />
              : null }
          </>
        )
      }
    }
  )
)

export default ProjectIndex
